import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          .fuck
        </h1>
        <h3>
          The .fuck top-level domain is secured by the <a className="App-link" href="https://handshake.org" target="_blank" rel="noopener noreferrer">handshake</a> root zone. <br />
          You can register .fuck names on the <a className="App-link" href="https://etherscan.io/token/0xfeddc1448eb4480714a9942ba28a27b16caf9ce4" target="_blank" rel="noopener noreferrer">Ethereum blockchain</a>.<br />
          Each name you register is an ERC-721 NFT.
        </h3>

        <h4>
          The .fuck project will evolve.
        </h4>
        <a
          className="App-link"
          href="https://impervious.domains/tld/fuck"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register .fuck domains
        </a>
        <a
          className="App-link"
          href="https://opensea.io/collection/impervious-registry?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Extension&search[stringTraits][0][values][0]=.fuck"
          target="_blank"
          rel="noopener noreferrer"
        >
          View .fuck domains on OpenSea
        </a>
        <a
          className="App-link"
          href="https://twitter.com/thefuckdao"
          target="_blank"
          rel="noopener noreferrer"
        >
          🐦 Twitter @thefuckdao
        </a>
      </header>
    </div>
  );
}

export default App;
